<template lang="pug">
v-container(style="min-height: 68vh")
  v-icon(large color="primary" @click="$router.go(-1)") mdi-keyboard-backspace
  v-overlay(opacity=".9" color="#f3f3f3" v-if="book==''").pl-lg-10
      v-progress-circular.ml-lg-15(
        :size="70"
        :width="7"
        color="primary"
        indeterminate)
  v-card(v-else v-for="book in book" :key="book.id").mt-15
    v-row
      v-col(md="4", cols="12")
        v-card.mx-auto.mt-n12.mb-8(height="270px", width="186px", flat, tile)
          .book
            img.cover(
              :src="book.imageUrl",
              width="186px",
              height="270px",
              style="object-fit: cover"
            )
      v-col.pa-md-8.px-10(md="8", cols="12")
        h3.font-weight-light {{ book.title }}
        h6.mt-3.font-weight-light(v-if="book.author.length == 1") Author: <strong> {{ book.author[0] }} </strong>
        h6.mt-3.font-weight-light(v-else) Author: <strong> {{ book.author[0] }}<span v-for="item in (book.author.slice(1,book.author.length-1))">, &nbsp {{ item }} &nbsp </span> & {{ book.author[book.author.length - 1] }} </strong>
        h6.font-weight-light Publisher: <span class="font-weight-regular"> {{ book.publisher }} </span>
        h6.font-weight-light Year: <span class="font-weight-regular"> {{ book.year }} </span>
        h6.font-weight-light(v-if="book.edition.length != 0") Edition: <span class="font-weight-regular"> {{ book.edition }} </span>
        h6.font-weight-light(v-if="typeof book.subject==='string'") Subject: <span class="font-weight-regular"> {{ book.subject }} </span>
        h6.font-weight-light(v-else) Subjects: <span class="font-weight-regular" v-for="subject in book.subject.slice(0,book.subject.length-1)">{{ subject }}, </span> <span class="font-weight-regular"> {{ book.subject[book.subject.length-1] }} </span>
        h6.font-weight-light(v-if="typeof book.institution==='string'") Institutions: <span class="font-weight-regular"> {{ book.institution }} </span>
        h6.font-weight-light(v-else) Institutions: <span class="font-weight-regular" v-for="inst in book.institution.slice(0,book.institution.length-1)">{{ inst }}, </span><span class="font-weight-regular"> {{ book.institution[book.institution.length-1] }} </span>          
        h6.font-weight-light(v-if="book.isbn.length != 0") ISBN: <span class="font-weight-regular"> {{ book.isbn }} </span>
        h6.font-weight-light(v-if="book.doi.length != 0") DOI: <span class="font-weight-regular"> {{ book.doi }} </span>
        h6.font-weight-light Views: <span class="font-weight-regular"> {{ book.views }} </span>

        .mt-6.text-md-end.text-center.mr-md-2.mb-md-0.mb-3
          addToShelf(:bookId="book.id" :bookTitle="book.title")
          
</template>

<script>
import { db } from "../main";
import addToShelf from "../components/AddToShelf.vue";
export default {
  components: { addToShelf },
  data() {
    return {
      book: "",
    };
  },

  async created() {
    let book_id = this.$route.params.id;
    this.$bind(
      "book",
      db
        .collection("books")
        .where("__name__", "==", book_id)
        .limit(1)
    );
  },
};
</script>

<style scoped>
div.book {
  width: 186px !important;
  height: 270 px;
  background-color: #333f48;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -webkit-perspective: 1200px;
  -moz-perspective: 1200px;
  perspective: 1200px;
}
.cover {
  width: 186px;
  -webkit-transform: rotateY(-30deg) !important;
  -moz-transform: rotateY(-30deg) !important;
  transform: rotateY(-30deg) !important;
  -webkit-transform-origin: 0;
  -moz-transform-origin: 0;
  transform-origin: 0;
}
.no-design {
  text-decoration: none !important;
}
.no-design :hover {
  text-decoration: none !important;
}
</style>
